<template>
    <div>
        <div class="main" :style="`background-image: url(${$util.host+bgImg});`">
            <div class="main-content">
                <div class="left-img">
                    <!-- <img src="../../assets/images/login/login-img.png" alt=""> -->
                </div>
                <div class="use-type">
                    <div class="user" @click="goPersonal">
                        <img class="user-icon" src="../../assets/images/login/user.png" alt="">
                        <div>
                            <div>我是个人用户</div>
                            <span>免费发布需求，在线投标接单</span>
                        </div>
                        <i style="font-size:30px;color:#666" class="el-icon-arrow-right"></i>
                    </div>
                    <div class="user" @click="goEnter">
                        <img class="user-icon" src="../../assets/images/login/qiye.png" alt="">
                        <div>
                            <div>我是企业用户</div>
                            <span>免费发布需求，在线投标接单</span>
                        </div>
                        <i style="font-size:30px;color:#666" class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			bgImg:''
        };
    },

    mounted() {

    },
	created() {
		this.login_logon_bg()
	},

    methods: {
		login_logon_bg() {
			let data = {
				paramKey:"login_logon_bg"
			}
			this.$util.post('/sys-param/list', data).then(res => {
				this.bgImg = res.data[0].paramValue
			})
		},
        goPersonal() {
            this.$router.push({ path: '/personRegister' })
        },
        goEnter() {
            this.$router.push({ path: '/enterRegister' })
        }
    },
};
</script>

<style lang="scss" scoped>
.main {
    // background: url("../../assets/images/login/login-bg.png") round;
    // background-color: #e40012;
	background-repeat: no-repeat;
	background-size: 100% 100%;
    height: 580px;
    .main-content {
        width: 1200px;
        margin: 0 auto 0;
        padding-top: 54px;
        display: flex;
        align-items: center;

        .left-img {
            width: 405px;
            height: 429px;
            margin-right: 250px;
            img {
                width: 100%;
                height: 100%;
            }
        }
     
        .user {
            cursor: pointer;
            background: #fff;
            font-weight: bold;
            padding: 0 30px;
            width: 430px;
            height: 122px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 22px;
            color: #333333;
            margin-top: 30px;
            span {
                font-size: 14px;
                color: #666666;
                margin-top: 10px;
            }
        }
        .user-icon {
            width: 43px;
            height: 49px;
        }
    }
}
</style>